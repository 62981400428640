<template>
<form @submit.prevent="">
    <div class="form-row">
        <div class="col-12">
            <input type="text" id="WatchList-title" placeholder="عنوان واچ لیست" class="widget-input input-no-bg input-lg input-set-border  w-100" v-model="WatchList.title">
        </div>
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveChanges()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary rename-WatchList"><i class="uil uil-save"></i> ذخیره تغییرات</a>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت مودال ویرایش واچ لیست می باشد
export default {
    name: 'WatchListEdit',
    props: ['data'],
    components: {},
    data: function () {
        return {
            WatchList: {
                title: '',
            }
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length) {
                this.loadData();
            }
        }, { immediate: true });
    },
    methods: {
        // تزریق و مقدار دهی اولیه به کامپوننت
        loadData() {
            this.WatchList = this.data.watchList;
        },
        // این متد وظیفه ارسال درخواست به پرنت جهت اعمال ویرایش روی واچ لیست مورد نظر را دارد
        saveChanges() {
            if (this.WatchList.title) {
                this.$parent.emitData('editWatchList', { title: this.WatchList.title }, true);
            }
        },
    },
}
</script>
